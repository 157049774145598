.four-o-four-wrapper {
    background: #F1F3F5;
    bottom: 0;
    color: #314851;
    left: 0;
    position: fixed;
    right: 0;
    top: 0; 
} 

.four-o-four-wrapper .four-o-four-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    overflow: hidden;
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.four-o-four-wrapper h2 {
    flex: none;
    font-weight: 700;
    font-size: 30px;
    margin:40px 0 10px;
}