.profile-btn {
    padding: 5px;
}

.delete-btn {
    color: red;
}

.edit-btn {
    color: blue;
}

/* .download-btn  */