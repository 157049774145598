/*.gecko-ui-btn.gecko-btn-edit {
    color: #428bca;
}

.gecko-ui-btn.gecko-btn-delete {
    color: #ea3103;
}

.gecko-ui-btn.gecko-btn-download {
    color: #646464;
}

.gecko-ui-btn.gecko-btn-cancel {
    float: right;
}*/

.profile-field {
    margin: 20px;
}
	
.profile-field > label {
    color: rgba(#1a252f, 0.6);
    font-size: 12px;
    margin-bottom: 5px;
}
    
.gecko-field-type-checkbox > div {
    overflow: scroll;
    max-height: 200px;

}

.gecko-field-type-radio > div {
    overflow: scroll;
    max-height: 200px;

}

.gecko-field {
    padding: 10px;
}

.profile-card-footer {
    text-align: right;
}
