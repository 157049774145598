/* .app-header
{	
	background:white;
	height:52px;
	padding:10px;
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.098), 0px 1px 5px 0px rgba(0, 0, 0, 0.0826);
} */
.banner {
  background-color: #314851;

}
.app-header .logo-container {
  width: 100%;
  z-index: 300;
  padding: 100px 0; 
}
  .app-header .logo-container > div {
    max-width: 800px;
    display: inline-block; 
  }
    .app-header .logo-container > div .logo {
      max-height: 100px;
      max-width: 400px;
      width: 100%;
      margin-bottom: 20px;
    }
    .app-header .logo-container > div .title {
      font-size: 20px;
      margin: 0; 
    }

.app-header .banner-container {
  position: absolute;
  height: 400px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1; }
  .app-header .banner-container .banner-mask {
    opacity: 0.9;
    height: 400px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .app-header .banner-container .banner {
    background-size: cover;
    height: 400px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center; }
