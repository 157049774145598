
.alert-success {
  padding: 25px;
}

.portal-wrapper {
  max-width: 960px;
  width: 96%;
  padding: 0;
  background: none;
  margin: 0 auto; 
}
