.app-footer 
{
  	margin-bottom: 20px;
  	/*font-size: 12px; */
}

.app-footer p
{
	margin:0;
}

.app-footer a {
	color: #004C97;;
	text-decoration: none;
	border-bottom: 1px solid #004C97;
}

.app-footer a:hover {
	color: #093b6d;
}