@import "https://fonts.geckoform.com/muli.css";

/* TEMP */
.gecko-card {
    margin-bottom: 20px !important; 
}

.bold {
    font-weight: 600; 
}

body {
    margin: 0;
    padding: 0; 
    background-color: #f1f3f5;
}

.app-body {
    padding: 20px 20px 0 20px;
    max-width: 960px;
    width:96%;
    padding: 0 0 20px 0;
    background: none;
    margin: 0 auto; 
}

.portal-wrapper .alert {
    margin-bottom: 0 !important;
}

.portal-wrapper h2.header-title {
    font-size: 18px;
    font-weight: 600;
}

.portal-wrapper .profile-field {
    margin: 0 16px 16px;
}

.portal-wrapper .gecko-ui-field input::placeholder {
    color: #6B6F70;
}


/*Show modal button on mobile*/
/*@media only screen and (max-width : 768px) {
}*/
