.header {
    padding: 30px;
    /* text-align: center; */
    color: #646464;
}

.attempt-again-btn {
    color: #428bca;
}

.attempt-again-btn:hover {
    color: #428bca;
    text-decoration: underline;
    cursor: pointer;
}

.attempt-again-text {
    color: #646464;
    font-size: 13px;
    padding: 20px;
}
