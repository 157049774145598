.gecko-btn-consent-to {
    color: #5e9e0a;
}

.gecko-btn-remove-all {
    color: #ea3103;
}

.gecko-btn-privacy-policy {
    color: #428bca;
}

@media only screen and (max-width : 500px) {


    .consent-field-wrapper .gecko-ui-btn
    {
        margin:0;
        
    }

    .consent-field-wrapper .gecko-ui-btns-sorted
    {
        display: block;
    }

    .consent-field-wrapper .gecko-ui-btns,
    .consent-field-wrapper .gecko-ui-btn
    {
        width: 100%;
        
    }

    .consent-field-wrapper .gecko-ui-btn
    {
        margin-top: 15px;
    }

    .consent-field-wrapper .gecko-ui-btns-sorted > div:first-child .gecko-ui-btn:first-child
    {
        margin: 0;
    }
}

@media only screen and (max-width : 360px) {

    .consent-field-wrapper .footer .pull-left,
    .consent-field-wrapper .footer .pull-right,
    .consent-field-wrapper .footer .gecko-btn
    {
        width:100%;
    }

    .consent-field-wrapper .footer .pull-right
    {
        margin-bottom: 15px;
    }

}

.gecko-table-header-cell-description {
    display: none;
}

.consent-icon-field {
    text-align: center;
    font-size: 50px;
    border-right: #d4e2c0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

ul.consent-reasons
{
    list-style-type: none;
    margin:0;
    padding:0;
}

ul.consent-reasons > li
{
    position: relative;
    border-top: 1px solid #e9eef4;
    /*border-top: 1px solid #cff0b0;*/
}

ul.consent-reasons > li:nth-child(odd) {
   background-color: #F1F3F5;
}

ul.consent-reasons > li:first-child
{
    border-top:none;
}

ul.consent-reasons > li.active
{   
    background: #e2f2de;
    border-top-color: #cff0b0;
}


.consent-row > div
{
    display: block;
    margin:0;
    font-weight:500;
    min-height: 60px; /*$checkbox-radio-size + 30px;*/
}

.consent-row > div label 
{
    font-weight: 600;
    color: #314851;
}

.consent-reason-meta
{
    width:100%;
    top: 0;
    left:0;
    padding: 12px 15px;
    padding-left: 60px;
    position:relative;
    z-index:0;
}

.consent-reason-meta .consent-title
{
    font-size: 15px;
}

.consent-reason-meta .consent-description
{
    font-size: 13px;
    color: #646464;
}

.consent-reason-checkbox 
{
    position:absolute;
    z-index:20;
    top:15px;
    left:15px;
}


.consent-reason-checkbox input[type="checkbox"] {
    opacity: 0;
}

.consent-reason-checkbox input[type="checkbox"] + div {
    height: 40px;
    margin:0;
    color: red;
    font-size: 20px;
    font-weight: 500;
    padding-left: 24px;
}

.consent-reason-checkbox input[type="checkbox"]:focus + div:before {
    border: 2px solid #172b4d;
}

.consent-reason-checkbox input[type="checkbox"] + div:before {
    content: '';
    border: 1px solid #808c99;
    width: 30px;
    height: 30px;
    background: white;
    position: absolute;
    top: 1px;
    left: 0;
    border-radius: 50%;
}

.active input[type="checkbox"] + div:before {
    border: 2px solid #3E742F;
}

.consent-reason-checkbox input[type="checkbox"]:checked + div:after {
    content: '';
    border: 2px solid #3E742F;
    border-top: none;
    border-right: none;
    width: 15px;
    height: 10px;
    display: block;
    position: absolute;
    transform: rotate(-45deg);
    left: 8px;
    top: 9px;
}
