
.success-icon {
    color: #5e9e0a;
    font-size: 100px;
}

.error-icon {
    color: #ea3103;
    font-size: 100px;
}

.message-container {
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}

.message-text {
    color: #646464;
    font-size: 18px;
    padding: 20px;
}

