ul.file-field
{
	list-style-position: inside;
	padding:0;
	margin:0;
}

ul.file-field > li
{
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}