.panel-options {
    text-align: center;
    padding: 30px;
}

.panel-option {
    margin: 0 5px;
    display: inline-block;
    height: 100px;
    min-width: 120px;
    font-weight: 10px;
    padding:0 15px #e5e5e5;
    border-radius: 3px;
    box-shadow: 0 6px 3px -5px rgba(44, 62, 80, 0.1);
    text-align: center;
    color: #3770a1;
    border: 1px solid #f7f7f7;
    margin: 20px;
}

div.panel-option.active {
	border: 1px solid;
	border-bottom-width: 3px;
	border-bottom-color: rgba(66, 139, 202, 0.8);
	background: rgba(66, 139, 202, 0.1);
	border-color: rgba(66, 139, 202, 0.3);
}

div.panel-option.disabled {
	cursor: not-allowed;
}

.panel-option-title{
	display: block;
}

.panel-option-icon {
	display: block;
}

.panel-option-title {
	font-size: 16px;
}

.panel-option-icon {
	padding: 14px 0 0 0;
	font-size: 34px;
}
